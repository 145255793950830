import {BrowserRouter} from "react-router-dom";
import React from 'react';


const Router = ({children}) => {
    return (
        <BrowserRouter>
            {children}
        </BrowserRouter>
    );
};

export default Router;