import React, { Component } from "react";
import Link from "../common/Link";
import allIcons from "../common/Icon";
import FAQ from "../component/FAQ";
import Footer from "../component/Footer";
import Fetch from "../common/Fetch";
import Modal from "../component/Modal";
import IFrame from "../component/IFrame";
import ErrorPage from "./ErrorPage";
import Header from "../component/Header";
// import Typist from 'react-typist';
import Typical from "react-typical";

const scrollToRef = (ref) => window.scrollTo(0, ref.offsetTop);



const redirect_to_website_endpoints = ["/psychiatry",
"/gobindrai",
"/ent",
"/pathology",
"/surgery",
"/medicine",
"/dermatology",
"/psm",
"/biochemistry",
"/radiology",
"/fmt",
"/ortho",
"/obgy",
"/microbiology",
"/anesthesia",
"/physiology",
"/anatomy",
"/optha",
"/pediatrics"];
class Home extends Component {
  state = {
    isLoading: true,
    showModal: true,
    data: { logo: "" },
    name: "",
    logo: "",
    email: "",
    phone: "",
    iframeHtml: "",
    iFrameHeight: 800,
    planErr : ""
  };

  componentDidMount() {
    
    const { location } = this.props;
    const pathname = location?.pathname || "";
    let path_index = -1;
    
    if (pathname !== "") {
      path_index = redirect_to_website_endpoints.indexOf(pathname);
    }

    if(path_index>-1){
      window.location = "https://www.prepladder.com";
      return
    }

    const { name, email, phone } = this.state;
    let params = {
      slug: this.props.match.params.id,
      name: name,
      email: email,
      phone: phone,
    };
    this.fetchData(params);

    window.addEventListener(
      "message",
      (e) => {
        if (e.data.height) {
          this.setState({ iFrameHeight: e.data.height });
        }
      },
      false
    );
  }

  fetchData = (params) => {
    /*
        psychiatry
        surgery
         microbiology
        */
    Fetch(
      "website/individual_apps_data",
      params,
      (res) => {
        // console.log(res.data);
        this.setState({ data: res.data, isLoading: false }, () => {
          this.setThemeColor(res.data.themeColor);
        });
      },
      { e: true }
    );
  };

  setThemeColor = (colorCode) => {
    document.documentElement.style.setProperty("--theme", colorCode);
  };

  toggleModle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  setUserInfo = (name, email, phone) => {
    this.setState(
      {
        name: name,
        email: email,
        phone: phone,
      },
      () => {
        this.joinNowModal();
      }
    );
  };

  joinNowModal = () => {
    const { name, email, phone } = this.state;
    let params = {
      slug: this.props.match.params.id,
      name: name,
      email: email,
      phone: phone,
    };
    this.toggleModle();
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.fetchData(params);
      }
    );
  };

  showFeatures = (data) => {
    if (data && data.length)
      return data.map((res, index) => {
        return (
          <div
            key={index.toString()}
            className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
          >
            <div className="features-col">
              <img src={res.img} />
              <h3>{res.title}</h3>
              <p>{res.para}</p>
            </div>
          </div>
        );
      });
    return;
  };

  showPacks = (packs) => {
    if (packs && packs.length)
      return packs.map((pack, indx) => {
        return (
          <li>
            <div className="own-dream-col">
              <div className="own-dream-col-body">
                <div className="own-dream-col-body-top">
                  <h3>{pack.packTitle}</h3>
                  <p>{pack.packInfo}</p>
                  <div className="price">{"₹ " + pack.price}</div>
                  <ul className="list-block">
                    {pack.featuresList.map((val) => {
                      return <li>{val}</li>;
                    })}
                  </ul>
                </div>
              </div>
              <button
                onClick={() => {
                  this.choosePlan(pack.packID);
                }}
                className="btn btn-theme btn-lg"
              >
                CHOOSE PLAN
              </button>
            </div>
          </li>
        );
      });
    return;
  };

  choosePlan = (packID) => {
    let params = {
      apiKey: "",
      appName: this.props.match.params.id,
      courseID: "1",
      email: this.state.email,
      packID,
      phone: this.state.phone,
      platform: "web",
      version: 1,
    };
    this.setState({ planErr : "", iframeHtml : "" });
    Fetch(
      "packageDetail",
      params,
      (res) => {
        if(res.status){
          this.setState({ iframeHtml: res.html, planErr : "" }, () => {
            scrollToRef(this.planFrame);
          });
        }else{
          this.setState({ iframeHtml : "", planErr : res.message },()=>{
            scrollToRef(this.planFrame)
          })
        }
      },
      { e: true }
    );
  };

  showVideo = (url) => {
    let videoLink = url.split("=");
    return (
      <iframe
        title="video"
        width="100%"
        height="100%"
        src={"https://www.youtube.com/embed/" + videoLink[1]}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    );
  };

  handleHeaderClick = (id) => {
    if (id === 1) {
      scrollToRef(this.featuresSection);
    }
    if (id === 2) {
      scrollToRef(this.packageSection);
    }
    if (id === 3) {
      scrollToRef(this.faqsRef);
    }
    if (id === 4) {
      scrollToRef(this.packageSection);
    }
  };

  render() {
    const { logo, sectionTop, themeColor, features, packages, faqs, appLinks  } =
      this.state.data;
    return (
      <>
        {this.state.isLoading ? (
          <div className="PopUp">
            <div className="PopUp-container">
              <div className="PopUp-spinner"></div>
              <p className="loading-text">Loading...</p>
            </div>
          </div>
        ) : logo !== "" ? (
          <div>
            <Header
              logo={logo}
              handleHeaderClick={(id) => {
                this.handleHeaderClick(id);
              }}
            />
            <main>
              <section className="banner">
                <div className="container">
                  <div className="row d-flex">
                    <div className="col-xl-7 col-lg-8 col-sm-12 mY-auto text-sm-center">
                      <div className="banner-content">
                        <h1>
                          {sectionTop && sectionTop.h1 + " "}
                          {/* <span className="color-theme">Pharmacology</span> */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sectionTop && sectionTop.t1,
                            }}
                          ></div>
                        </h1>

                        <div className="" style={{ paddingBottom: "30px" }}>
                          {!this.state.showModal ? (
                            <Typical
                              steps={[sectionTop.p1, 1000]}
                              loop={Infinity}
                              wrapper="p"
                            />
                          ) : null}
                        </div>

                        {/* <Typist cursor={
                          {
                            show: false,
                          }
                        }>
                        
                        <p>{sectionTop && sectionTop.p1}</p>
                        </Typist> */}
                        <div>
                          <button
                            onClick={() => scrollToRef(this.packageSection)}
                            style={{ backgroundColor: themeColor }}
                            className="btn btn-theme btn-lg"
                          >
                            join now
                          </button>
                        </div>
                        <div className="img-group mT20">
                          <img
                            style={{ cursor: "pointer" }}
                            alt=""
                            onClick={() => {
                              appLinks && window.open(appLinks.ios, "_blank");
                            }}
                            src={allIcons.appStore}
                          />
                          <img
                            style={{ cursor: "pointer" }}
                            alt=""
                            onClick={() => {
                              appLinks &&
                                window.open(appLinks.android, "_blank");
                            }}
                            src={allIcons.playStore}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-4 d-sm-none">
                      <div className="banner-img">
                        {sectionTop && (
                          <img alt="" src={sectionTop.deviceImage} />
                        )}
                        {/* <img src={allIcons.headerImage} /> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-8 col-sm-11 float-none m-auto">
                      <div className="home-video video">
                        {sectionTop && this.showVideo(sectionTop.videoLink)}
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div
                className="container"
                ref={(ref) => (this.featuresSection = ref)}
              >
                <section className="features section-spacing">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-5 col-lg-7 col-md-10 col-sm-12 m-auto float-none">
                        <div className="section-heading text-center mB30">
                          <h2>{features && features.h2}</h2>
                          <p>{features && features.t2}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {features && this.showFeatures(features.data)}
                    </div>
                  </div>
                </section>
              </div>
              <section className="own-dream section-spacing bg-light">
                <div
                  ref={(ref) => (this.packageSection = ref)}
                  className="container"
                >
                  <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 m-auto float-none">
                      <div className="section-heading text-center mB30">
                        <h2>{packages && packages.h3}</h2>
                        <p>{packages && packages.t2}</p>
                      </div>
                    </div>
                  </div>
                  <ul className="list-block own-dream-list">
                    {packages && this.showPacks(packages.packs)}
                  </ul>
                </div>
              </section>

              {(this.state.iframeHtml !== "" || this.state.planErr !== "") && (
                <section
                  style={{ background: "#f5f5f5" }}
                  class="enroll-sec section-spacing"
                  id="champions"
                >
                  <div ref={(ref) => (this.planFrame = ref)} class="container">
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-12 float-none m-auto">
                        <div class="section-heading text-center">
                          <h2>Enroll Now</h2>
                          <p class="mB10">
                            To start preparing with the Dream Team
                          </p>
                          {/* <span class="color-red" style={{paddingBottom:"30px"}}>
                            Please note : Notes delivery will be hampered in
                            containment zones
                          </span> */}
                        </div>
                      </div>
                    </div>
                   {this.state.iframeHtml!=="" ? <IFrame
                      srcDoc={this.state.iframeHtml}
                      style={{
                        height: this.state.iFrameHeight + "px",
                        border: 0,
                        marginTop: "30px",
                      }}
                      width="100%"
                    /> : <div className="error_msg">{this.state.planErr}</div> }
                  </div>
                </section>
              )}

              <section className="trust-banner">
                <div className="container">
                  <div className="section-heading text-center">
                    <h2 className="color-white">
                      Trusted by over 80k students
                    </h2>
                    <p className="color-white">
                      Boost your learning anytime, anywhere with our <br />
                      iPhone and Android apps.
                    </p>
                    <div className="img-group mT20">
                      <img
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          appLinks && window.open(appLinks.ios, "_blank");
                        }}
                        src={allIcons.appStore}
                      />
                      <img
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          appLinks && window.open(appLinks.android, "_blank");
                        }}
                        src={allIcons.playStore}
                      />
                    </div>
                  </div>
                </div>
              </section>

              <div ref={(ref) => (this.faqsRef = ref)}>
                {faqs && <FAQ faqs={faqs} />}
              </div>
              <Footer />
              {!this.state.isLoading && this.state.showModal && (
                <Modal
                  show={this.state.showModal}
                  close={() => this.toggleModle()}
                  join={(name, email, phone) => {
                    this.setUserInfo(name, email, phone);
                  }}
                />
              )}
            </main>
          </div>
        ) : (
          <ErrorPage />
        )}
      </>
    );
  }
}

export default Home;
