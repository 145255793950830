import React from 'react';
import {Link as ClickLink} from 'react-router-dom';
// import PropTypes from 'prop-types';

const Link = ({children,title,to,href,className,onClick,style,hrefLinkNewTab,id}) => { //path={pathname:'/abc',hash:'/'}
    return (
            href
            ?
            <a title={title} href={href} id={id} target={hrefLinkNewTab && '_blank'} className={className + ' text-deco-none'} style={style}>{children}</a>
            :
            to
            ?
            <ClickLink title={title} to={to} id={id} className={className + ' text-deco-none'} style={style} onClick={onClick}>
                {children}
            </ClickLink>
            :
            <div id={id} className={className + ' text-deco-none'} style={style} onClick={onClick}>
                {children}
            </div>
    );
};

// Link.propTypes={
//     path:PropTypes.oneOfType([PropTypes.string,PropTypes.object]),
// }

export default Link;