import React from "react";
import allIcons from "../common/Icon";

const Header = ({ logo, handleHeaderClick }) => {
  return (
    <header>
      <div className="container text-sm-center">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <a>
              <img src={logo} className="logo" alt="Logo" />
            </a>
          </div>
          <div className="col-xl-8 col-lg-8 col-sm-12">
            <ul className="list-inline text-right menu text-uppercase">
              <li className="menu-item">
                <a
                  style={{cursor:'pointer'}}
                  onClick={() => {
                    handleHeaderClick(1);
                  }}
                >
                  features
                </a>
              </li>
              <li className="menu-item">
                <a
                style={{cursor:'pointer'}}
                  onClick={() => {
                    handleHeaderClick(2);
                  }}
                >
                  pricing
                </a>
              </li>
              <li className="menu-item">
                <a
                style={{cursor:'pointer'}}
                  onClick={() => {
                    handleHeaderClick(3);
                  }}
                >
                  faqs
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    handleHeaderClick(4);
                  }}
                  className="btn btn-theme btn-sm"
                >
                  join now
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
