import React, {Suspense} from 'react';
import {Switch, Route} from "react-router-dom";
import Routes from "./common/Routes";
import ErrorPage404 from "./pages/ErrorPage";

import HandleLayout from './Layout/HandleLayout';
import HandleRouteAuth from './common/HandleRouteAuth';
import Router from  './component/Router';


const App = () => {
   
    return (
            <Router>
                <Suspense fallback={() => <div>Loading..</div>}>
                        <Switch>
                            {Routes.map((routeInfo, index) => {
                                return (
                                    <Route
                                        exact
                                        path={routeInfo.path}
                                        key={index}
                                        component={(props) => 
                                            <HandleRouteAuth
                                                uniqueKey={routeInfo.key}
                                                path={routeInfo.path} 
                                                authRequired={routeInfo.authRequired} 
                                                component={()=> 
                                                            <HandleLayout layoutName={routeInfo.layout}> 
                                                                    <routeInfo.component {...props}/> 
                                                            </HandleLayout>
                                                        }
                                        />}
                                    />
                                );
                            })}
                            <Route component={ErrorPage404}/>
                        </Switch>
                </Suspense>
            </Router>
    )


}
export default App;