import React, { Component } from "react";

class authLayout extends Component {
    render(){
        return(
            <div className="authLayout">
                <div className="authLayout_card">
                    {this.props.children}
                </div>
            </div>
        )
    }
}
export default authLayout;