import React, { Component } from "react";
import validator from "validator";
import Fetch from "../common/Fetch";

import allIcons from "../common/Icon";
import CountryCodePicker from "../component/CountryCodePicker";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      err: "",
      countryCode : "+91",
      countryCodeList : []
    };
  }

  componentDidMount(){
    Fetch("getCountryCodes",null,(res) => {
      this.setState({ countryCodeList : res.countryCodeList })
     }).catch(e=>console.log(e));
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { name, email, phone, countryCode } = this.state;
    if (name.trim().length === 0) {
      this.setState({ err: "Please enter name !" });
      return;
    }
    if (email.trim().length === 0) {
      this.setState({ err: "Please enter Email !" });
      return;
    }
    if (!validator.isEmail(email)) {
      this.setState({ err: "Please enter valid email address !" });
      return;
    }
    if (phone.trim().length === 0) {
      this.setState({ err: "Please enter Phone !" });
      return;
    }
    if (countryCode == "+91") {
      if (!validator.isMobilePhone(phone, "en-IN")) {
        this.setState({ err: "Please enter valid phone number !" });
        return;
      }
    }else{
      if(!(phone.length>7 && phone.length < 16)){
        this.setState({ err: "Please enter valid phone number !" });
        return
      }
    }
    this.setState({ err : "" })
    let ph = countryCode+phone;
    this.props.join(name, email,ph );
  };

  render() {
    const { show, close } = this.props;
    let { countryCode, countryCodeList } = this.state;

    return (
      <div className="modal">
        <div className="modal-container">
          <div className="modal-head text-right">
            {/* <img
              onClick={() => {
                close();
              }}
              src={allIcons.crossIcon}
              className="cross-btn"
            /> */}
          </div>
          <form className="form">
            <div className="form-group">
              <label>Name*</label>
              <input
                type="text"
                required
                className="form-control"
                onChange={(e) => {
                  this.setState({ name: e.target.value.trim() });
                }}
              />
            </div>
            <div className="form-group">
              <label>Email *</label>
              <input
                type="email"
                required
                className="form-control"
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />
            </div>
            <div className="form-group">
              <label>Phone Number *</label>
              <div className="countryCode form-control">
                <div className="countryCode_list">
                  <CountryCodePicker classNamePrefix="selectCountryCode" className="selectCountryCode1" getCountryCode={(code)=>this.setState({countryCode: code})}/>
                {/* <select
                onChange={(e)=>{this.setState({ countryCode : e.target.value })}}
                >
                  {countryCodeList.map((item,index)=>(<option key={index} value={item.dialerCode}>{item.dialerCode}</option>))}
                  </select>
                <label>{countryCode} <img src="https://image.prepladder.com/content/cnmAtcd7QVkJ3Lkx3opg1637063988.svg" /></label> */}
                </div>

              <input
                type="tel"
                required
                onChange={(e) => {
                  this.setState({ phone: e.target.value });
                }}
              />
              </div>
            </div>
            {this.state.err !== "" && (
              <p className="mY10 color-red text-center">{this.state.err}</p>
            )}
            <div className="form-group">
              <input
                type="submit"
                value="JOIN NOW"
                className="btn-theme form-control"
                onClick={(e) => {
                  this.onSubmit(e);
                }}
              />
            </div>
          </form>
        </div>
        <div className="modal-bg"></div>
      </div>
    );
  }
}

export default Modal;
