import React from 'react';
import {Redirect} from "react-router-dom";
import {withRouter} from "react-router";
import {withContext} from '../context/AppContext';


const HandleRouteAuth = (props) => {

    return (
        
        props.authRequired && !props.context.isLogin
        ?
        <Redirect
            to={`/login?redirect=${props.location.pathname}${
                props.location.search
                }`}
        />
       :
        <props.component {...props}/>
        
    );
};

export default withContext(withRouter(HandleRouteAuth));