import React from 'react';
import MainLayout from './MainLayout';
import AuthLayout from './authLayout';
import EmptyLayout from './EmptyLayout';
// import { withContext } from '../context/AppContext';


const Layouts={
    "main":MainLayout,
    "auth":AuthLayout,
    "empty":EmptyLayout
}

const HandleAuthAndLayout = ({children,layoutName}) => {
    
    let Comp =  Layouts[layoutName] ? Layouts[layoutName] : Layouts.empty;

    return (
        <Comp>
            {children}
        </Comp>
    );
};

export default HandleAuthAndLayout;