import React from "react";
import Collapsible from "react-collapsible";

const FAQ = ({ faqs }) => (
  <main className="faq">
    <div className="section-spacing bg-light">
      <div className="container">
        <div className="section-heading text-center mB30">
          <h2>Frequently Asked Questions</h2>
        </div>

        <div className="row">
          <div className="col-xl-9 col-lg-10 col-sm-12 float-none m-auto">
            <div className="panel">
              {faqs.map((res, indx) => {
                return (
                  <Collapse
                    open={indx == 0 ? true : false}
                    title={res.title}
                    information={res.text}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
);

export default FAQ;

const Collapse = ({ open, title, information }) => {
  return (
    <Collapsible
      open={open}
      className="panel-box"
      openedClassName="panel-box active"
      triggerClassName="panel-head"
      triggerOpenedClassName="panel-head"
      transitionTime={200}
      trigger={
        <div
          style={{ textTransform: "capitalize", display: "block" }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      }
    >
      <div
        className="panel-content"
        dangerouslySetInnerHTML={{ __html: information }}
      />
    </Collapsible>
  );
};
