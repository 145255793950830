import React from 'react';
import {withContext} from "../context/AppContext";
import Header from "../component/Header";


 function MainLayout({children}) {
    return (
        <div>
            <Header />
            <main>
                {children}
            </main>
        </div>
    );
}
export default withContext(MainLayout);