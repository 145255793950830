import React, { useRef, useEffect } from "react";

const IFrame = ({
  onLoad,
  src,
  title,
  style,
  id,
  srcDoc,
  frameborder,
  height,
  width,
  key
}) => {
  const ifrm = useRef(null);
  useEffect(() => {
    if (ifrm.current.contentDocument) {
      const doc = ifrm.current.contentWindow.document;
      doc.open();
      doc.write(srcDoc ? srcDoc : src);
      doc.close();
     
    }
  }, [srcDoc, src, onLoad]);

  return (
    <iframe
      ref={ifrm}
      id={id}
      style={style}
      onLoad={onLoad}
      title={title}
      frameBorder={frameborder}
      height={height}
      width={width}
      key={key}
    />
  );
};

export default IFrame;
