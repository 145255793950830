import React from 'react';
import * as ReactDOM from "react-dom";
// import App from './App';
import './style/main.scss';
import AppContext from './context/AppContext';
import App from './App';

// import {BrowserRouter} from "react-router-dom";

window.cached={}



ReactDOM.render(
    <AppContext >
       <App/>
    </AppContext>
  ,
  document.getElementById('root')
);

