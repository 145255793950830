import React, {Component, createContext} from "react";


export const MainContext = createContext();

class AppContext extends Component {
    

    render() {
        return (
            <MainContext.Provider
                value={{
                    ...this.state,
                }}
            >
                {this.props.children}
            </MainContext.Provider>
        );
    }
}

export default AppContext;

export const withContext = Components => props => {
    return (
        <MainContext.Consumer>
            {value => <Components context={value} {...props} />}
        </MainContext.Consumer>
    );
};