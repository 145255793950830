import React from 'react';
import {Redirect} from 'react-router-dom';
import Home from "../pages/Home";
import About from "../pages/About";
import ContactUs from "../pages/ContactUs";


const Routes = [
  {
    path: "/contactus",
    component: ContactUs,
    layout: "auth",
  },
  {
    path: "/about",
    component: About,
    layout: "main",
  },
  {
    path: "/:id",
    component: Home,
    // layout: "main",
  },
  {
    path: "/",
    component: ()=><Redirect to='/psychiatry'/>,
    // layout: "main",
  },
];
export default Routes;
