import axios from "axios";
import UrlList from "./Urls";
import {encodeData,decodeData} from '../common/encode'


const commonParams={
    // appID:1,
    // "platform":"web",
    // "version":"1"

}



export default function Fetch(
  extendedURL,
  extendedParams = {},
  callBack,
  options
) {
  const enc = options?.e === false ? false : true;
  const cachedData = options?.cacheData;


  const params = {
    ...commonParams,
    ...extendedParams
  };

  const hash = params?.hash;

  if (hash in window.cached) {
    cachedData && cachedData(window.cached[hash]);
    callBack && callBack(window.cached[hash]);
  }

  if ("hash" in params) {
    delete params["hash"];
  }

  const request = encodeData(JSON.stringify(params));

  return axios
    .post(UrlList[0] + extendedURL, enc ? { enc_request: request } : params, {})
    .then(data => {
      if (enc && data.data?.enc_response) {
        // console.log(`response----req_params`,decodeData(request));
        // console.log(`response----result`,decodeData(data.data.enc_response) )
        if (hash) {
          window.cached[hash] = decodeData(data.data.enc_response);
        }
        console.log(decodeData(data.data.enc_response));
        callBack && callBack(decodeData(data.data.enc_response));
        return decodeData(data.data.enc_response);
      }
      callBack && callBack(data.data);
      return data.data;
    })
    .catch(e => {
      callBack &&
        callBack({ status: false, message: "Internal server error!" });
      return Promise.reject("Server Error");
    });
}

// export default function Fetch(endPoint,extendedParams,options) {
//     const indexOfUrl=options?.indexOfUrls;
//     const url=options?.url;
//     const method=options?.method;
//     const commonParam=options?.params;
  
//     return  axios[method ? method : "post"](url ? url : indexOfUrl ? UrlList[indexOfUrl] : UrlList[0] + endPoint,
//       commonParam ? commonParam: {
//         ...commonParams,
//         ...extendedParams
//       })
//       .then(d => {
//         return d.data;
//       })
//       .catch(e => Promise.reject(e));
  
// }
