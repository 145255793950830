const staticImages={
    // logo:'https://storage.googleapis.com/prepladder-images/medical/logo-ndewkefklefnenfsnmsnkjsn.svg',
    // logo: require('../Assets/logo.png'),
    // logo_small: require('../Assets/logo_sm.png'),
}

///THIS IMPORTS ALL IMAGES FROM ASSEST FOLDER AND YOU CAN ACCESS BY THEIR NAMES
const context = require.context('../assets', false, /\.(png|jpe?g|svg)$/);


function camelize(text) {
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
    return text.substr(0, 1).toLowerCase() + text.substr(1);
}
const images=(context)=>{
    const obj = {};
    context.keys().forEach((key) => {
        const data = key.split('./').pop() // remove the first 2 characters
            .substring(0, key.length - 6); // remove the file extension
        
        obj[camelize(data)] = context(key);
    });
    return obj;
}

const allIcons=Object.assign({},staticImages,images(context));

module.exports=allIcons