import React from 'react';

const ErrorPage = () => {
    return (
        <div style={{display:'flex',height:'100vh',fontSize:'30px',justifyContent:'center',alignItems:'center'}}>
            404 Error
        </div>
    );
};

export default ErrorPage;