import React from 'react';

function Footer() {
    return (
    <footer className="bg-light">
        <div className="container f-14 text-center">
            <p> &copy; Copyright {new Date().getFullYear()} PrepLadder • 
            <a href="https://prepladder.com/termsandconditions.html" className="color-default"> Terms of Service</a> • 
            <a href="https://prepladder.com/privacypolicy.html" className="color-default"> Privacy Policy</a></p>
           
        </div>
    </footer>
    )
 
}

export default Footer
